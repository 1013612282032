import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import {
  FaFacebook,
  // FaInstagram,
  FaGoogle,
  FaWhatsapp,
  FaInfoCircle,
  FaBriefcase,
  FaEnvelope,
  FaHome,
} from "react-icons/fa";
import "../styling/Navigation.css"; // custom CSS styles

const Navigation = () => {
  return (
    <Navbar bg="light" expand="lg" className="navbar-custom" fixed="top">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="#home" className="text-danger custom-link">
            <FaHome className="icon" /> <span className="linktext">Home</span>
          </Nav.Link>
          <Nav.Link href="#about" className="text-danger custom-link">
            <FaInfoCircle className="icon" />
            <span className="linktext">About</span>
          </Nav.Link>
          <Nav.Link href="#services" className="text-danger custom-link">
            <FaBriefcase className="icon" />
            <span className="linktext">Services</span>
          </Nav.Link>
          <Nav.Link href="#contact" className="text-danger custom-link">
            <FaEnvelope className="icon" />
            <span className="linktext">Contact</span>
          </Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link
            href="https://www.facebook.com/TRIPLEMULTITECH"
            className="text-danger"
          >
            <FaFacebook className="socialicon" />
          </Nav.Link>
          <Nav.Link
            href="https://g.page/r/CbyFLdA_avXEEAI/review"
            className="text-danger"
          >
            <FaGoogle className="socialicon" />
          </Nav.Link>
          <Nav.Link href="wa.me/+2348162552901" className="text-danger">
            <FaWhatsapp className="socialicon" />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
