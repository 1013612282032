import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import logo from "../images/TMTLOGO.png";
import bgImage from "../images/tmtbackground.jpg";
// import { Link } from "react-router-dom";
import Navigation from "./Nav";
import "../styling/Landing.css";
// import ScrollTrigger from 'react-scroll-trigger';
import { useState, useEffect } from "react";

const LandingPage = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoaded(true);
    };
    img.src = logo;
  }, []);
  return (
    <>
      <Navigation />
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        id="home"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
        }}
      >
        <Container fluid className="h-100">
          <Row className="justify-content-end align-items-end custom-row">
            <Col
              xs={12}
              md={6}
              lg={4}
              className="d-flex flex-column justify-content-center align-items-center custom-column Colcorection"
            >
              <img
                src={logo}
                alt="Company logo"
                className={`img-fluid size fade-in ${loaded ? "loaded" : ""}`}
              />
            </Col>
          </Row>
          <Row className="h-100 thisrow">
            <Col
              xs={12}
              md={6}
              lg={4}
              className="mb-4 d-flex flex-column justify-content-center align-items-center custom-column Colcorection"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Nav.Link
                  href="#about"
                  className="btn btn-outline-light text-white mb-2 hover-effect p-2"
                >
                  <span className="myhover">ABOUT US</span>
                </Nav.Link>
                <Nav.Link
                  href="#services"
                  className="btn btn-outline-light mb-2 hover-effect text-white p-2"
                >
                  <span className="myhover">OUR SERVICES</span>
                </Nav.Link>
                <Nav.Link
                  href="#why-choose-us"
                  className="btn btn-outline-light mb-2 hover-effect text-white p-2"
                >
                  <span className="myhover">WHY CHOOSE US</span>
                </Nav.Link>
                <Nav.Link
                  href="#contact"
                  className="btn btn-outline-light mb-2 hover-effect text-white p-2"
                >
                  <span className="myhover">CONTACT US NOW</span>
                </Nav.Link>
                <Nav.Link
                  href="#contact"
                  className="btn btn-outline-light mb-2 hover-effect text-white p-2"
                >
                  <span className="myhover">CONSULTANCY SERVICE</span>
                </Nav.Link>
                <Nav.Link
                  href="#contact"
                  className="btn btn-outline-light mb-2 hover-effect text-white p-2"
                >
                  <span className="myhover">GET A SERVICE QUOTE</span>
                </Nav.Link>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={8}
              className="d-flex flex-column justify-content-end align-items-end custom-column Coltitle"
            >
              <h2 className="text-center text-white smallsc">Welcome to</h2>
              <h1 className="text-center text-white ">
                TRIPLE MULTIPURPOSE TECHNOLOGY
              </h1>
              <h3>
                {" "}
                <small class="text-muted">
                  Your Surest Plug for Innovative Technology
                </small>
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LandingPage;
