import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../images/comapny.jpg";

const AboutUs = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
        minHeight: "100vh",
      }}
    >
      <Container>
        <Row id="about">
          <Col md={4} className="p-4">
            <div className="bg-danger text-white p-3 rounded mb-3 text-center">
              <h2>Brief Information</h2>
              <p>
                TRIPLE MULTIPURPOSE TECHNOLOGY is a Nigerian indigenous Business
                duly registered with the Cooperate Affairs Commission of Nigeria
                under COMPANIES AND ALLIED MATTER ACT 1990, on the 29th Day of
                April, 2019 with registration number BN: 2875632. Triple
                Multipurpose Technology as one of the indigenous company, offers
                specialized services in areas such as IT, Engineering and
                Training.
              </p>
            </div>
            <div className="bg-danger text-white p-3 rounded mb-3">
              <h2>Mission</h2>
              <p>
                Boost the Nation’s Economy in aspect of IT and Engineering
                Service
              </p>
            </div>
          </Col>
          <Col md={4} className="p-4">
            <div className="bg-danger text-white p-3 rounded mb-3">
              <h2>Vision</h2>
              <p>To become the Leading IT Service Provider in the Nation</p>
            </div>
            <div className="bg-danger text-white p-3 rounded mb-3">
              <h2>Value Preposition</h2>
              <p>
                To provide Services That Touch Customer's Heart and Meet Their
                Demand.
              </p>
            </div>
          </Col>
          <Col md={4} className="p-4">
            <div className="bg-danger text-white p-3 rounded mb-3">
              <h2>Business Objective</h2>
              <p>
                To Provide Employment/Investment Opportunity for Technology
                Enthusiasm within the Nations
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
