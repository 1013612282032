import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faShieldAlt,
  faClock,
  faLaptop,
} from "@fortawesome/free-solid-svg-icons";
import "../styling/whyus.css";

const WhyChooseUs = () => {
  return (
    <div className="why-choose-us" id="why-choose-us">
      <Container>
        <h2 className="text-center mb-5">Why Choose Us</h2>
        <Row>
          <Col xs={6} md={6} lg={3} className="mb-5">
            <div className="choose-us-item">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faBolt} className="icon" />
              </div>
              <h3 className="title">Fastest Service</h3>
              <p className="description">
                We understand the importance of speed, and we strive to deliver
                our services as quickly as possible without compromising on
                quality.
              </p>
            </div>
          </Col>
          <Col xs={6} md={6} lg={3} className="mb-5">
            <div className="choose-us-item">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faShieldAlt} className="icon" />
              </div>
              <h3 className="title">Top-Notch Security</h3>
              <p className="description">
                We take security seriously and implement the latest security
                measures to ensure that your data and information are safe with
                us.
              </p>
            </div>
          </Col>
          <Col xs={6} md={6} lg={3} className="mb-5">
            <div className="choose-us-item">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faClock} className="icon" />
              </div>
              <h3 className="title">24/7 Service</h3>
              <p className="description">
                We are always available to assist you whenever you need us,
                whether it's during regular business hours or outside of them.
              </p>
            </div>
          </Col>
          <Col xs={6} md={6} lg={3} className="mb-5">
            <div className="choose-us-item">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faLaptop} className="icon" />
              </div>
              <h3 className="title">Latest Technology</h3>
              <p className="description">
                We keep up-to-date with the latest technologies and tools to
                ensure that we provide you with the best possible service and
                solutions.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhyChooseUs;
