import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import MapComponent from "./map";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-danger text-light pt-5 myfooter">
      <Container className="contain">
        <Row className="justify-content-between align-items-center">
          <Col xs={12} md={4} className="mb-4 mb-md-0">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                size="2x"
                className="me-3"
              />
              <div>
                <h5>Address</h5>
                <p>
                  No. H11 Baba Musami Plaza, adjacent to Borno State Civil
                  Service Commission, Post Office Area, Maiduguri, Borno State.
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4} className="mb-4 mb-md-0">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faPhone} size="2x" className="me-3" />
              <div>
                <h5>Phone</h5>
                <p>+234 816 255 2901</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faEnvelope} size="2x" className="me-3" />
              <div>
                <h5>Email</h5>
                <p>info@triplemultipurposetech.com</p>
              </div>
            </div>
          </Col>
        </Row>
        <hr className="bg-light" />
        {/* <Row> */}
        {/* <Col xs={12} md={4} className="mb-4 mb-md-0">
            <h5 className="mb-3">Triple Multipurpose Technology</h5>
            <ul className="list-unstyled">
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </Col> */}
        {/* <Col xs={12} md={8}>
            <!-- prettier-ignore --> */}
        <MapComponent />
        {/* <iframe
              title="Triple Multipurpose Technology"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253262.06535467244!2d7.471104!3d9.063629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b927c6eb50999%3A0x55816bfef989d6!2sLagos!5e0!3m2!1sen!2sng!4v1621230599316!5m2!1sen!2sng"
              width="100%"
              height="400"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe> */}
        {/* <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={16}
            >
              <Marker position={center} />
            </GoogleMap> */}
        {/* </Col>
        </Row> */}
        <div className="text-center text-white pt-3">
          <p className="text-center">
            Copyright &copy; {currentYear} Triple Multipurpose Technology. All
            rights reserved. Developed by{" "}
            <a href="https://www.triplemultipurposetechnology.com">
              Triple Multipurpose Technology
            </a>
            .
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
