import React from "react";
import LandingPage from "./components/Landing";
import WhyChooseUs from "./components/whyus";
// import logo from './logo.svg';
import "./styling/App.css";
import AboutUs from "./components/Aboutus";
import OurServices from "./components/Services";
import ContactUs from "./components/contact";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <LandingPage />
      <AboutUs />
      <WhyChooseUs />
      <OurServices />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
