import React, { useEffect } from "react";

const MapComponent = () => {
  useEffect(() => {
    let map;

    async function initMap() {
      const position = { lat: 11.837872, lng: 13.147519 };
      const { Map } = await window.google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
        "marker"
      );

      map = new Map(document.getElementById("map"), {
        zoom: 15,
        center: position,
        mapId: "3e3b23ab8773e248",
      });

      const marker = new AdvancedMarkerElement({
        map: map,
        position: position,
        title: "Triple Multipurpose Technology",
      });
    }

    initMap();
  }, []);

  return <div id="map" style={{ height: "400px", width: "100%" }}></div>;
};

export default MapComponent;
