import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
// import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
// import axios from "axios";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaWhatsapp,
} from "react-icons/fa";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Forward the message to WhatsApp
    const whatsappLink = `https://wa.me/2348162552901?text=${encodeURIComponent(
      `Name: ${name}\nPhone: ${phone}\nMessage: ${message}`
    )}`;
    window.open(whatsappLink, "_blank");

    // Clear form fields
    setName("");
    setPhone("");
    setMessage("");
  };

  return (
    <div className="contact-us" id="contact">
      <Container>
        <h2 className="text-center mb-5">Contact Us</h2>
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <div className="d-flex align-items-center mb-3">
              <FaPhoneAlt size={30} className="me-3" />
              <div>
                <h5 className="mb-0">Triple Multipurpose Technology</h5>
                <p className="mb-0">08162552901</p>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <FaEnvelope size={30} className="me-3" />
              <div>
                <h5 className="mb-0">Email</h5>
                <p className="mb-0">info@triplemultipurposetechnology.com</p>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <FaWhatsapp size={30} className="me-3" />
              <div>
                <h5 className="mb-0">whatsapp</h5>
                <p className="mb-0">+2348162552901</p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <FaMapMarkerAlt size={30} className="me-3" />
              <div>
                <h5 className="mb-0">Address</h5>
                <p className="mb-0">
                  No. H11 Baba Musami Plaza, adjacent to Borno State Civil
                  Service Commission, Post Office Area, Maiduguri, Borno State.
                </p>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} className="mb-4 form">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPhone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formBasicMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter your message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>

              <Button variant="danger" type="submit" className="button ">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
