import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styling/services.css";
import {
  FaLaptop,
  FaMobile,
  FaPencilAlt,
  FaPrint,
  FaWrench,
  FaNetworkWired,
} from "react-icons/fa";

const OurServices = () => {
  const [currentFlipBox, setCurrentFlipBox] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFlipBox((prevFlipBox) => (prevFlipBox + 1) % 6);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const flipBoxes = [
    {
      icon: FaNetworkWired,
      title: "Networking",
      description:
        "We design, install, maintain and provide training on computer networks for organizations or individuals.",
      frontClass: "networking",
    },
    {
      icon: FaLaptop,
      title: "Web Development",
      description:
        "We develop custom web applications that are secure, scalable, and easy to use and provide webdevelopment training",
      frontClass: "web",
    },
    {
      icon: FaMobile,
      title: "PWA Development",
      description:
        "We build native and hybrid mobile apps that deliver an amazing user experience and help you achieve your business goals as well as provide training.",
      frontClass: "pwa",
    },
    {
      icon: FaPencilAlt,
      title: "Graphic Design",
      description:
        "We create stunning graphics and visual assets for your brand and train individual on graphic design.",
      frontClass: "graphic",
    },
    {
      icon: FaPrint,
      title: "Printing Services",
      description:
        "We offer high-quality printing services for your marketing and branding needs.",
      frontClass: "printing",
    },
    {
      icon: FaWrench,
      title: "AutoCAD Services",
      description:
        "We provide detailed 2D, 3D design and Modeling for your product, whether it's an MVP or the original as well as provide AutoCAD training.",
      frontClass: "autocad",
    },
  ];

  return (
    <div className="servicecontainer">
      <div id="services" className="our-services text-center">
        <div className="services-bg">
          <Container className="container-fluid">
            <Row className="justify-content-center">
              <Col xs={12} className="mb-4">
                <h2 className="services-title text-danger">Our Services</h2>
              </Col>
              {flipBoxes.map((flipBox, index) => (
                <Col key={index} xs={6} md={6} lg={4}>
                  <div
                    className={`flip-box flip-box-horizontal ${
                      currentFlipBox === index ? "flip-box-active" : ""
                    }`}
                  >
                    <div className="flip-box-inner">
                      <div
                        className={`flip-box-front ${flipBox.frontClass}`}
                      ></div>
                      <div className="flip-box-back">
                        <div className="service-icon">
                          <flipBox.icon size={40} />
                        </div>
                        <h3 className="service-title">{flipBox.title}</h3>
                        <p className="service-description">
                          {flipBox.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
